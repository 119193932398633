import * as React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Background from "components/Background/Background";
import TitleSubtitle from "components/blocks/TitleSubtitle/TitleSubtitle";
import Gallery from "components/Gallery/Gallery";
const Guides = ({ data }) => {
  const block = data.datoCmsGuidesoverview;
  const seo = block.seoMetaTags;
  const title = block.title;
  const subtitle = block.subtitle;
  const guides = data.guides.nodes;
  const titleSubtitleBlock = {
    title,
    subtitle,
    pageHeader: true,
  };

  return (
    <>
      <HelmetDatoCms seo={seo} />
      <Background
        height={block.height}
        bgColor={block.backgroundColor}
        viewHeight={true}
      >
        <TitleSubtitle block={titleSubtitleBlock} />
        <Gallery items={guides} />
      </Background>
    </>
  );
};

export const query = graphql`
  query {
    datoCmsGuidesoverview {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      navbarColors
      title
      subtitle
      height
      backgroundColor {
        hex
      }
    }
    guides: allDatoCmsGuidepage(
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        ...GuidePageGalleryItem
      }
    }
  }
`;

export default Guides;
